import React, { Component } from "react";
import axios from "axios";

const BASE_URL =
  "https://my-json-server.typicode.com/Gold-Dev726/json-database/authors";
const authorData = [
  {
    id: 1,
    img: "/img/auction_1.jpg",
    avatar: "/covers/cover_1.jpg",
    author: "Rod Woodson",
    btnText: "Follow",
  },
//   {
//     id: 1,
//     img: "/img/auction_1.jpg",
//     avatar: "/covers/cover_1.jpg",
//     author: "Rodney Pete",
//     btnText: "Follow",
//   },
  {
    id: 2,
    img: "/img/auction_2.jpg",
    avatar: "/covers/cover_2.jpg",
    author: "Tim Couch",
    btnText: "Follow",
  },
  {
    id: 3,
    img: "/img/auction_3.jpg",
    avatar: "/covers/cover_3.jpg",
    author: "Kenyatta Wright",
    btnText: "Follow",
  },
  {
    id: 4,
    img: "/img/auction_4.jpg",
    avatar: "/covers/cover_4.jpg",
    author: "Eric Davis",
    btnText: "Follow",
  },
  {
    id: 5,
    img: "/img/auction_5.jpg",
    avatar: "/covers/cover_5.jpg",
    author: "Don Carey Ill",
    btnText: "Follow",
  },
  {
    id: 6,
    img: "/img/auction_6.jpg",
    avatar: "/covers/cover_6.jpg",
    author: "Donnell Thompson",
    btnText: "Follow",
  },
  {
    id: 7,
    img: "/img/auction_7.jpg",
    avatar: "/covers/cover_7.jpg",
    author: "Dick Anderson",
    btnText: "Follow",
  },
  //   {
  //     id: 8,
  //     img: "/img/auction_8.jpg",
  //     avatar: "/covers/cover_8.jpg",
  //     author: "Dennis Brown",
  //     btnText: "Follow",
  //   },
];
class Authors extends Component {
  state = {
    data: {},
    authorData: [],
  };
  componentDidMount() {
    this.setState({
      authorData: authorData,
    });
  }
  //   componentDidMount() {
  //     axios
  //       .get(`${BASE_URL}`)
  //       .then((res) => {
  //         this.setState({
  //           data: res.data,
  //           authorData: res.data.authorData,
  //         });
  //         // console.log(this.state.data)
  //       })
  //       .catch((err) => console.log(err));
  //   }
  render() {
    return (
      <section className="popular-collections-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center">
                <span>Creators</span>
                <h3 className="mt-3 mb-0">Our Creators</h3>
                <p>
                  {" "}
                  Each NFT project created with a limited number. When they are
                  gone, they’re gone. If you miss out, you can buy them from the
                  Marketplace. Easily proven because ownership can be checked on
                  the publicly accessible blockchain by matching the digital
                  wallet address.
                </p>
              </div>
            </div>
          </div>
          <div className="row items">
            {this.state.authorData.map((item, idx) => {
              return (
                <div
                  key={`ad_${idx}`}
                  className="col-12 col-sm-6 col-lg-3 item"
                >
                  <div className="card no-hover text-center">
                    <div className="image-over">
                      <a href={`/author/${item.id}`}>
                        <img className="card-img-top" src={item.img} alt="" />
                      </a>
                      {/* Seller */}
                      <a className="seller" href="/author">
                        <div className="seller-thumb avatar-lg">
                          <img
                            className="rounded-circle"
                            src={item.avatar}
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body mt-4">
                        <a href="/author">
                          <h5>{item.author}</h5>
                        </a>
                        <a
                          className="btn btn-bordered-white btn-smaller"
                          href="#"
                        >
                          {item.btnText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Authors;
