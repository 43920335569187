import React, { Component } from "react";
import axios from "axios";

const data = [
  {
    id: "1",
    img: "/img/auction_1.jpg",
    date: "2021-12-09",
    title: "Virtual Worlds",
    seller_thumb: "/img/avatar_1.jpg",
    seller: "@Richard",
    price: "1.5 CRWD",
    count: "1 of 1",
  },
  {
    id: "2",
    img: "/img/auction_2.jpg",
    date: "2021-10-05",
    title: "Collectibles",
    seller_thumb: "/img/avatar_2.jpg",
    seller: "@JohnDeo",
    price: "2.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "3",
    img: "/img/auction_3.jpg",
    date: "2021-09-15",
    title: "Arts",
    seller_thumb: "/img/avatar_3.jpg",
    seller: "@MKHblots",
    price: "2.3 CRWD",
    count: "1 of 1",
  },
  {
    id: "4",
    img: "/img/auction_4.jpg",
    date: "2021-12-29",
    title: "Robotic Arts",
    seller_thumb: "/img/avatar_4.jpg",
    seller: "@RioArham",
    price: "1.8 CRWD",
    count: "1 of 1",
  },
  {
    id: "5",
    img: "/img/auction_5.jpg",
    date: "2022-01-24",
    title: "Magazine Fall",
    seller_thumb: "/img/avatar_5.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "6",
    img: "/img/auction_6.jpg",
    date: "2022-03-30",
    title: "Inspiration",
    seller_thumb: "/img/avatar_6.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "7",
    img: "/img/auction_7.jpg",
    date: "2022-01-24",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_7.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "8",
    img: "/img/auction_8.jpg",
    date: "2022-03-30",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_8.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "9",
    img: "/img/auction_9.jpg",
    date: "2022-03-30",
    title: "Design Illusions",
    seller_thumb: "/img/avatar_4.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "10",
    img: "/img/auction_10.jpg",
    date: "2022-03-30",
    title: "Infinity",
    seller_thumb: "/img/avatar_1.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "11",
    img: "/img/auction_11.jpg",
    date: "2022-01-24",
    title: "Sports",
    seller_thumb: "/img/avatar_2.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
  {
    id: "12",
    img: "/img/auction_12.jpg",
    date: "2022-03-30",
    title: "Characteristics",
    seller_thumb: "/img/avatar_3.jpg",
    seller: "@ArtNox",
    price: "1.7 CRWD",
    count: "1 of 1",
  },
];

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json/seller";

class TopSeller extends Component {
  state = {
    data: {},
    sellerData: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          sellerData: data,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <section className="top-seller-area p-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro m-0">
                <div className="intro-content">
                  <span>{this.state.data.preHeading}</span>
                  <h3 className="mt-3 mb-0">{this.state.data.heading}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            {this.state.sellerData.map((item, idx) => {
              return (
                <div
                  key={`ts_${idx}`}
                  className="col-12 col-sm-6 col-lg-4 item"
                >
                  {/* Single Seller */}
                  <div className="card no-hover">
                    <div className="single-seller d-flex align-items-center">
                      <a href="/author">
                        <img
                          className="avatar-md rounded-circle"
                          src={item.img}
                          alt=""
                        />
                      </a>
                      {/* Seller Info */}
                      <div className="seller-info ml-3">
                        <a className="seller mb-2" href="/author">
                          {item.seller}
                        </a>
                        <span>{item.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default TopSeller;
