import React, { Component } from "react";
import axios from "axios";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json-1/author";

class AuthorProfile extends Component {
  state = {
    data: {},
    socialData: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          socialData: res.data.socialData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    const id = this.props.match ? this.props.match.params.id : null;
    return (
      <div className="card no-hover text-center">
        {id ? (
          <>
            <div className="image-over">
              <img
                className="card-img-top"
                src={`/img/auction_${id}.jpg`}
                alt=""
              />
              {/* Author */}
              <div className="author">
                <div className="author-thumb avatar-lg">
                  <img
                    className="rounded-circle"
                    src={`/covers/cover_${id}.jpg`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* Card Caption */}
            <div className="card-caption col-12 p-0">
              {/* Card Body */}
              <div className="card-body mt-4">
                <h5 className="mb-3">Rod Woodson</h5>
                <p className="my-3">
                  Roderick Kevin Woodson (born March 10, 1965) is an American
                  former professional football player in the National Football
                  League (NFL) for 17 seasons. He had a 10-year stint with the
                  Pittsburgh Steelers and was a key member of the Baltimore
                  Ravens' Super Bowl XXXV championship team that beat the New
                  York Giants. He also played for the San Francisco 49ers and
                  Oakland Raiders, wearing the jersey number 26 throughout his
                  career. Widely considered one of the game's all-time greatest
                  defensive players, Woodson holds the NFL record for fumble
                  recoveries (32) by a defensive player, and interceptions
                  returned for touchdown (12), and was named the NFL Defensive
                  Player of the Year in 1993. His 71 career interceptions is the
                  third-most in NFL history. He was an inductee of the Class of
                  2009 of the Pro Football Hall of Fame in Canton, Ohio on
                  August 8, 2009. Woodson was inducted into the College Football
                  Hall of Fame in 2016. Rod played most of his career as a
                  cornerback then switched to safety during the later part of
                  his career.
                </p>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.state.data.authorId}
                  />
                  <div className="input-group-append">
                    <button>
                      <i className="icon-docs" />
                    </button>
                  </div>
                </div>
                {/* Social Icons */}
                <div className="social-icons d-flex justify-content-center my-3">
                  {this.state.socialData.map((item, idx) => {
                    return (
                      <a key={`asd_${idx}`} className={item.link} href="#">
                        <i className={item.icon} />
                        <i className={item.icon} />
                      </a>
                    );
                  })}
                </div>
                <a className="btn btn-bordered-white btn-smaller" href="#">
                  Follow
                </a>
              </div>
            </div>
          </>
        ) : (
          <img className="card-img-top" src={`/img/create.png`} alt="" />
        )}
      </div>
    );
  }
}

export default AuthorProfile;
