import React, { Component } from "react";
import axios from "axios";
const initData = {
  pre_heading: "Exclusive Assets",
  heading: "Explore",
  btn_1: "View All",
  btn_2: "Load More",
};

const data = [
  {
    id: "1",
    img: "/covers/cover_1.jpg",
    title: "Walking On Air",
    owner: "Richard",
    price: "1.5 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "2",
    img: "/covers/cover_2.jpg",
    title: "Domain Names",
    owner: "John Deo",
    price: "2.7 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "3",
    img: "/covers/cover_3.jpg",
    title: "Trading Cards",
    owner: "Arham",
    price: "2.3 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "4",
    img: "/covers/cover_4.jpg",
    title: "Industrial Revolution",
    owner: "Yasmin",
    price: "1.8 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "5",
    img: "/covers/cover_5.jpg",
    title: "Utility",
    owner: "Junaid",
    price: "1.7 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "6",
    img: "/covers/cover_6.jpg",
    title: "Sports",
    owner: "ArtNox",
    price: "1.9 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "7",
    img: "/covers/cover_7.jpg",
    title: "Cartoon Heroes",
    owner: "Junaid",
    price: "3.2 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "8",
    img: "/covers/cover_8.jpg",
    title: "Gaming Chair",
    owner: "Johnson",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "9",
    img: "/covers/cover_9.jpg",
    title: "Photography",
    owner: "Sara",
    price: "2.3 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "10",
    img: "/covers/cover_10.jpg",
    title: "Zed Run",
    owner: "SpaceMan",
    price: "3.7 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "11",
    img: "/covers/cover_11.jpg",
    title: "Rare Tyres",
    owner: "Monas",
    price: "2.2 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: "12",
    img: "/covers/cover_12.jpg",
    title: "World of Women",
    owner: "Victor",
    price: "4.3 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
];

const BASE_URL =
  "https://my-json-server.typicode.com/Gold-Dev726/json-database/explore";

class ExploreOne extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          initData: res.data,
          data: data,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <section className="explore-area load-more p-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro d-flex justify-content-between align-items-end m-0">
                <div className="intro-content">
                  <span>{this.state.initData.preHeading}</span>
                  <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                </div>
                <div className="intro-btn">
                  <a className="btn content-btn" href="/explore-3">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`exo_${idx}`}
                  className="col-12 col-sm-6 col-lg-3 item"
                >
                  <div className="card">
                    <div className="image-over">
                      <a href={`/item-details/1`}>
                        <img className="card-img-top" src={item.img} alt="" />
                      </a>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body">
                        <a href={`/item-details/1`}>
                          <h5 className="mb-0">{item.title}</h5>
                        </a>
                        <div className="seller d-flex align-items-center my-3">
                          <span>Owned By</span>
                          <a href="/author">
                            <h6 className="ml-2 mb-0">{item.owner}</h6>
                          </a>
                        </div>
                        <div className="card-bottom d-flex justify-content-between">
                          <span>{item.price}</span>
                          <span>{item.count}</span>
                        </div>
                        <a
                          className="btn btn-bordered-white btn-smaller mt-3"
                          href="/login"
                        >
                          <i className="icon-handbag mr-2" />
                          {item.btnText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <a id="load-btn" className="btn btn-bordered-white mt-5" href="#">
                {this.state.initData.btnText}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ExploreOne;
