import React, { Component } from "react";
import axios from "axios";

const initData = {
  pre_heading: "Explore",
  heading: "Exclusive Digital Assets",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  filter_1: "All",
  filter_2: "NFL",
  filter_3: "LEGENDS",
  filter_4: "HOF",
  filter_5: "COLLEGE",
};

const data = [
  {
    id: 1,
    img: "/covers/cover_1.jpg",
    title: "Rod Woodson",
    owner: "Rod Woodson",
    price: "1.5 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 2,
    img: "/covers/cover_2.jpg",
    title: "Tim Couch",
    owner: "Tim Couch",
    price: "2.7 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 3,
    img: "/covers/cover_3.jpg",
    title: "Kenyatta Wright",
    owner: "Kenyatta Wright",
    price: "2.3 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 4,
    img: "/covers/cover_4.jpg",
    title: "Eric Davis",
    owner: "Eric Davis",
    price: "1.8 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 5,
    img: "/covers/cover_5.jpg",
    title: "Don Carey Ill",
    owner: "Don Carey Ill",
    price: "1.7 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 6,
    img: "/covers/cover_6.jpg",
    title: "Donnell Thompson",
    owner: "Donnell Thompson",
    price: "1.9 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 7,
    img: "/covers/cover_7.jpg",
    title: "Dick Anderson",
    owner: "Dick Anderson",
    price: "3.2 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 8,
    img: "/covers/cover_8.jpg",
    title: "Dennis Brown",
    owner: "Dennis Brown",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 9,
    img: "/covers/cover_9.jpg",
    title: "Danny Noonan",
    owner: "Danny Noonan",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 10,
    img: "/covers/cover_10.jpg",
    title: "Devin Wyman",
    owner: "Devin Wyman",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 11,
    img: "/covers/cover_11.jpg",
    title: "Curt Warner",
    owner: "Curt Warner",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
  {
    id: 12,
    img: "/covers/cover_12.jpg",
    title: "Darrell Thompson",
    owner: "Darrell Thompson",
    price: "0.69 CRWD",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
];

const BASE_URL =
  "https://my-json-server.typicode.com/Gold-Dev726/json-database/explore";

class ExploreThree extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }
  render() {
    return (
      <section className="explore-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              {/* Intro */}
              <div className="intro text-center mb-4">
                <span>{this.state.initData.pre_heading}</span>
                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                <p>{this.state.initData.content}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-12">
              {/* Explore Menu */}
              <div
                className="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-4"
                data-toggle="buttons"
              >
                <label className="btn active d-table text-uppercase p-2">
                  <input
                    type="radio"
                    defaultValue="all"
                    defaultChecked
                    className="explore-btn"
                  />
                  <span>{this.state.initData.filter_1}</span>
                </label>
                <label className="btn d-table text-uppercase p-2">
                  <input
                    type="radio"
                    defaultValue="art"
                    className="explore-btn"
                  />
                  <span>{this.state.initData.filter_2}</span>
                </label>
                <label className="btn d-table text-uppercase p-2">
                  <input
                    type="radio"
                    defaultValue="music"
                    className="explore-btn"
                  />
                  <span>{this.state.initData.filter_3}</span>
                </label>
                <label className="btn d-table text-uppercase p-2">
                  <input
                    type="radio"
                    defaultValue="collectibles"
                    className="explore-btn"
                  />
                  <span>{this.state.initData.filter_4}</span>
                </label>
                <label className="btn d-table text-uppercase p-2">
                  <input
                    type="radio"
                    defaultValue="sports"
                    className="explore-btn"
                  />
                  <span>{this.state.initData.filter_5}</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row items explore-items">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`edth_${idx}`}
                  className="col-12 col-sm-6 col-lg-3 item explore-item"
                  data-groups={item.group}
                >
                  <div className="card">
                    <div className="image-over">
                      <a href={`/item-details/${item.id}`}>
                        <img className="card-img-top" src={item.img} alt="" />
                      </a>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                      {/* Card Body */}
                      <div className="card-body">
                        <a href={`/item-details/${item.id}`}>
                          <h5 className="mb-0">{item.title}</h5>
                        </a>
                        <div className="seller d-flex align-items-center my-3">
                          <span>Owned By</span>
                          <a href="/author">
                            <h6 className="ml-2 mb-0">{item.owner}</h6>
                          </a>
                        </div>
                        <div className="card-bottom d-flex justify-content-between">
                          <span>{item.price}</span>
                          <span>{item.count}</span>
                        </div>
                        <a
                          className="btn btn-bordered-white btn-smaller mt-3"
                          href="/wallet-connect"
                        >
                          <i className="icon-handbag mr-2" />
                          {item.btnText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default ExploreThree;
